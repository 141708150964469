import React from 'react';
import DynamicForm from './DynamicForm';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    <div className="App w-full h-screen flex flex-col justify-center items-center ">
     {/* bg-gradient-to-bl from-orange-300 to-gray-900  */}
      <DynamicForm />
    </div>
    </BrowserRouter>
  );
}

export default App;
